<template>
  <div class="space-y-5">
    <div class="flex items-center justify-between gap-2.5 relative">
      <ButtonEl
        icon="more_horiz"
        color="color2"
        @click="showMoreOptions = true"
      />
      <ButtonEl text="Anwenden" @click="apply()" />
      <Transition name="fade">
        <div
          v-if="showMoreOptions"
          ref="moreOptionsContainer"
          class="absolute top-0 w-full bg-white rounded-md standard-elevation-0-dark z-10 overflow-hidden"
        >
          <div
            v-for="option in moreOptionsFilterSet.filter(
              (option) => !option.disabled,
            )"
            :key="option"
            class="flex items-center gap-1.5 p-3 hover:bg-active-area cursor-pointer"
            @click="option.action()"
          >
            <IconWrapper :icon="option.icon" fill="text-title-neutral" />
            <div class="body-2 pt-[3px] text-neutral">
              {{ option.label }}
            </div>
          </div>
        </div>
      </Transition>
    </div>

    <v-list v-model:opened="open" class="standard-container hide-scrollbar">
      <v-list-group
        v-for="[key, value] of Object.entries(categoriesNamingMap)"
        :key="key"
        :value="value"
      >
        <template #activator="{ props: activatorProps }">
          <v-list-item
            v-bind="activatorProps"
            :title="value"
            :ripple="false"
            :class="[
              'hover:text-color1 px-0',
              { 'text-color1': open?.includes(value) },
            ]"
          />
        </template>
        <div class="px-1 flex flex-col gap-2.5">
          <CheckboxEl
            label="Zeige alle"
            :value="key"
            :model-value="areAllSelected(key).value"
            @click="toggleAll(key)"
          />
          <CheckboxEl
            v-for="sub of categoriesData.filter(
              (e) => e.restriction_category === key,
            )"
            :key="sub.restriction_sub_category"
            v-model="selected"
            :label="subCategoriesNamingMap[sub.restriction_sub_category]"
            :value="sub.restriction_sub_category"
          />
        </div>
      </v-list-group>
    </v-list>
  </div>
</template>

<script setup>
import { usePotentialAnalysisStore } from '@/apps/usecase-2/potential-analysis/potential-analysis-store';
import { storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import ButtonEl from '@/components/button/ButtonEl.vue';
import { LAYER_KEY__RESTRICTION } from '@/apps/features/map/layer-config/base-data';
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import axios from '@/utils/axiosHelper';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import { onClickOutside } from '@vueuse/core';
import { useToastStore } from '@/stores/toast-store';

const potentialStore = usePotentialAnalysisStore();
const { showToast } = useToastStore();

const props = defineProps({
  mapStore: {
    type: Object,
  },
});

await potentialStore.fetchRestrictions();

const { categoriesNamingMap, subCategoriesNamingMap, categoriesData } =
  storeToRefs(usePotentialAnalysisStore());

const selected = ref([]);
const open = ref();

const showMoreOptions = ref(false);
const moreOptionsContainer = ref(null);

const moreOptionsFilterSet = ref([
  {
    label: 'Aus Analyse übernehmen',
    icon: 'add',
    action: () => {
      applyFromAnalysis();
      showMoreOptions.value = false;
    },
  },
  {
    label: 'Zurücksetzen',
    icon: 'replay',
    action: () => {
      reset();
      showMoreOptions.value = false;
    },
  },
]);

onClickOutside(moreOptionsContainer, () => {
  showMoreOptions.value = false;
});

const categoryToSubcategories = new Map();
categoriesData.value.forEach((item) => {
  if (!categoryToSubcategories.has(item.restriction_category)) {
    categoryToSubcategories.set(item.restriction_category, []);
  }
  categoryToSubcategories
    .get(item.restriction_category)
    .push(item.restriction_sub_category);
});

const areAllSelected = (key) =>
  computed(() => {
    const subcategories = categoryToSubcategories.get(key) || [];
    const selectedSet = new Set(selected.value);
    return subcategories.every((sub) => selectedSet.has(sub));
  });

function toggleAll(key) {
  const subcategories = categoryToSubcategories.get(key) || [];
  const allSelected = areAllSelected(key).value;
  if (allSelected) {
    selected.value = selected.value.filter(
      (sub) => !subcategories.includes(sub),
    );
  } else {
    selected.value = Array.from(new Set([...selected.value, ...subcategories]));
  }
}

function apply(fromAnalysis = false) {
  props.mapStore.addFilter(
    [LAYER_KEY__RESTRICTION],
    ['in', ['get', 'restriction_sub_category'], ['literal', selected.value]],
    'restrictionFilter',
  );
  showToast({
    color: 'success',
    message: fromAnalysis
      ? 'Filter für Restriktionsflächen aus Analyse übernommen und angewendet'
      : 'Filter für Restriktionsflächen angewendet',
    timeout: 2000,
  });
}

async function applyFromAnalysis() {
  const restrictionData = await axios({
    method: 'GET',
    url: `/api/usecase-2/restrictions-by-analysis/${props.mapStore.selectedAnalysis.id}`,
  });
  selected.value = restrictionData.data.sub_categories;
  apply(true);
}

function reset(withToast = true) {
  selected.value = [];
  for (const valueArray of categoryToSubcategories.values()) {
    for (const value of valueArray) {
      selected.value.push(value);
    }
  }
  props.mapStore.removeFilter([LAYER_KEY__RESTRICTION], 'restrictionFilter');
  if (withToast) {
    showToast({
      color: 'success',
      message: 'Filter für Restriktionsflächen zurückgesetzt',
      timeout: 2000,
    });
  }
}

reset(false);
</script>

<style scoped lang="scss">
:deep(.v-list-item:hover) {
  .v-list-item__overlay {
    display: none;
  }
}
</style>
