<template>
  <VExpansionPanels
    v-model="openPanel"
    variant="accordion"
    class="gap-2.5 expansion-panels-custom"
    :multiple="multiple"
    :eager
  >
    <VExpansionPanel
      v-for="(panel, index) in panels"
      :key="panel"
      style="border-radius: 12px !important"
    >
      <VExpansionPanelTitle
        class="p-5 flex justify-between items-center hover:bg-subtle"
      >
        <CircleWithNumber
          v-if="showCircleNumber"
          :number="index + 1"
          class="mr-3"
        />
        <h3
          :class="showCircleNumber ? 'text-title-neutral' : 'text-title-color1'"
          class="pb-0.5 mr-auto"
        >
          {{ panel }}
        </h3>
        <template #actions>
          <IconWrapper
            :class="{ 'rotate-180': openPanel === index }"
            fill="text-core-color1"
            class="duration-300"
            icon="keyboard_arrow_down"
          />
        </template>
      </VExpansionPanelTitle>

      <VExpansionPanelText>
        <slot :name="`panel${index + 1}`" />
      </VExpansionPanelText>
    </VExpansionPanel>
  </VExpansionPanels>
</template>
<script setup>
import { ref } from 'vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import CircleWithNumber from '@/components/ExpansionPanel/components/CircleWithNumber.vue';

const props = defineProps({
  panels: {
    type: Array,
    default: () => [],
  },
  firstPanelOpen: {
    type: Boolean,
    default: true,
  },
  showCircleNumber: {
    type: Boolean,
    default: true,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  allPanelsOpen: {
    type: Boolean,
    default: false,
  },
  eager: {
    type: Boolean,
    default: false,
  },
});
const openPanel = ref();
if (props.multiple && props.allPanelsOpen) {
  openPanel.value = Array.from(
    { length: props.panels.length + 1 },
    (_, index) => index,
  );
} else {
  openPanel.value = props.firstPanelOpen ? 0 : null;
}
</script>

<style lang="scss" scoped>
.expansion-panels-custom {
  .v-expansion-panel::after {
    display: none;
  }
}
</style>
