import { Ref, ref, watch } from 'vue';
// @ts-ignore
import { useBaseStore } from '@/apps/basemodule/base-store';

interface PostalCode {
  isSelectable: boolean;
  title: string;
  name: string;
  id: string;
  geometry_json: string;
}

interface PostalCodeResponseEntry {
  code: string;
  geometry_json: string;
  id: number;
  county_name: string;
  admin_district_name: string;
}

interface MultiSelectItem {
  name: string;
  value: string;
  expanded: boolean;
  checked: boolean;
  items: Array<MultiSelectItem>;
}

export function useSyncGeoMapPostalCodeSelection(
  groupBy: Ref<'county_name' | 'admin_district_name'>,
  preSelectedPostalCodes = new Set(),
) {
  const baseStore = useBaseStore();
  const selected = ref<string[]>([]);
  const postalCodeSelectItems = ref<MultiSelectItem[]>([]);
  const postalCodes = ref<PostalCode[]>([]);
  const postalCodeResponse = ref<PostalCodeResponseEntry[]>([]);
  baseStore.fetchSimplifiedPostalCodeData().then((res) => {
    postalCodeResponse.value = res;
    populatePostalCodes();
  });

  function setSelected(postalCodeId: string) {
    selected.value.push(postalCodeId);
    togglePostalCodeSelectItem(postalCodeId);
  }

  function setUnSelected(postalCodeId: string) {
    const index = selected.value.findIndex((e) => e === postalCodeId);
    selected.value.splice(index, 1);
    togglePostalCodeSelectItem(postalCodeId);
  }

  function togglePostalCodeSelectItem(
    postalCodeId: string,
    item = postalCodeSelectItems.value[0],
  ) {
    if (item.items.length > 0) {
      item.items.forEach((child) =>
        togglePostalCodeSelectItem(postalCodeId, child),
      );
      return;
    }
    if (item.value === postalCodeId.toString()) {
      item.checked = !item.checked;
    }
  }

  function populatePostalCodes() {
    const createItem = (value: string, labelEnd = '') => ({
      name: `${value}${labelEnd}`,
      value: value,
      expanded: false,
      checked: false,
      items: [],
    });
    const rootEntry: MultiSelectItem = {
      name: 'Alle PLZs',
      value: 'all',
      checked: false,
      expanded: false,
      items: [],
    };
    const filterItemsMap: Record<string, MultiSelectItem> = {};

    const sortedPostalCodes = postalCodeResponse.value.sort((a, b) =>
      a.code.localeCompare(b.code),
    );
    for (const postalCode of sortedPostalCodes) {
      postalCodes.value.push({
        isSelectable: true,
        title: `${postalCode.code} - ${postalCode.county_name}`,
        name: postalCode.id.toString(),
        id: postalCode.id.toString(),
        geometry_json: postalCode.geometry_json,
      });

      const firstGroupingLevel = postalCode[groupBy.value];

      if (!(firstGroupingLevel in filterItemsMap)) {
        filterItemsMap[firstGroupingLevel] = createItem(firstGroupingLevel);
      }

      const getEntry = (level: number, prevEntry: MultiSelectItem) => {
        const digits = postalCode.code.slice(0, level);
        const existingEntry = prevEntry.items.find(
          (item) => item.value === digits,
        );
        if (existingEntry) {
          return existingEntry;
        }
        const newEntry = createItem(digits, '...');
        prevEntry.items.push(newEntry);
        return newEntry;
      };

      const firstTwoEntry = getEntry(2, filterItemsMap[firstGroupingLevel]);
      const firstThreeEntry = getEntry(3, firstTwoEntry);

      firstThreeEntry.items.push({
        name: postalCode.code,
        value: postalCode.id.toString(),
        checked: preSelectedPostalCodes.has(postalCode.id.toString()),
        expanded: false,
        items: [],
      });
    }
    rootEntry.items = Object.values(filterItemsMap).sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    postalCodeSelectItems.value.push(rootEntry);
  }

  watch(
    postalCodeSelectItems,
    (newVal: MultiSelectItem[]) => {
      const selectedSet = new Set(selected.value);
      const update = (items: MultiSelectItem[] = newVal[0].items) =>
        items.forEach(handleItem);
      const handleItem = (item: MultiSelectItem) => {
        if (item.items.length > 0) {
          update(item.items);
          return;
        }
        if (item.checked) {
          selectedSet.add(item.value);
          return;
        }
        selectedSet.delete(item.value);
      };
      update();
      selected.value = Array.from(selectedSet);
    },
    { deep: true },
  );

  watch(groupBy, () => {
    reinitialize();
    populatePostalCodes();
  });

  const reinitialize = () => {
    postalCodes.value = [];
    postalCodeSelectItems.value = [];
    selected.value = [];
  };

  return {
    setSelected,
    setUnSelected,
    selected,
    postalCodeSelectItems,
    postalCodes,
  };
}
