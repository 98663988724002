<template>
  <TopHeaderBar />
  <div
    class="flex gap-5 bg-default rounded-[16px] p-5 standard-elevation-2 w-full h-full sales-region-create-container"
  >
    <FormValidator v-model="isFormValid">
      <div class="flex flex-col min-w-[420px] gap-7">
        <InputEl
          v-model="salesRegion.title"
          :label="salesRegionMetaData.title.label"
          :rules="{ required: true }"
          placeholder="Bitte eingeben"
          unique-check-url="/api/usecase-4/sales-region/names/"
        />
        <div class="flex flex-col gap-5">
          <h4 class="flex gap-2 items-center">
            Gebietsauswahl
            <ToolTip
              :tooltip-text="`Es können maximal ${maxSelectablePostalCodes} PLZ in einem Vertriebsgebiet zusammengefasst werden.`"
            ></ToolTip>
          </h4>
          <div class="flex flex-col gap-5 px-5">
            <div>
              <span class="body-2 next-neutral"
                >PLZ-Auswahl gruppieren nach:</span
              >
              <RadioButtonGroup
                v-model="groupBy"
                items-data-key="label"
                :items-data="[
                  { value: 'admin_district_name', label: 'Regierungsbezirken' },
                  { value: 'county_name', label: 'Landkreisen' },
                ]"
              />
            </div>
            <div>
              <DropDownMultipleGrouped
                v-model="postalCodeSelectItems"
                class="w-full"
                :items="postalCodeSelectItems"
                placeholder="PLZs auswählen"
                :max-preview-items="1"
                label="PLZ"
                item-type-all="PLZs"
                :level-adjustments="[
                  { indent: 0, fontClass: '' },
                  { indent: 20, fontClass: '' },
                  { indent: 30, fontClass: '' },
                  { indent: 40, fontClass: '' },
                  { indent: 50, fontClass: 'font-light' },
                ]"
                :custon-preview-text="customPreviewText"
              />
              <span
                v-if="selected.length > maxSelectablePostalCodes"
                class="bottom-[-17px] right-0 text-xxs text-spot-error whitespace-nowrap"
              >
                Es können max. {{ maxSelectablePostalCodes }} PLZ in einem
                Vertriebsgebiet zusammengefasst werden
              </span>
            </div>
            <CheckboxEl v-model="displayExistingProjects">
              <span class="body-2 next-neutral"
                >Gebiete mit bestehenden Projekten einfärben</span
              >
            </CheckboxEl>
          </div>
        </div>
      </div>
    </FormValidator>

    <div class="w-full geo-chart-custom-height">
      <GeoChart
        v-if="postalCodes.length > 0"
        :selected="selected"
        :geo-items="preprocessedPostalCodes"
        :enable-roam="true"
        @geo-unselected="setUnSelected($event)"
        @geo-selected="setSelected($event)"
      />
      <div
        v-else
        class="flex justify-center items-center geo-chart-custom-height"
      >
        <VProgressCircular
          class="text-title-color1"
          size="30"
          width="3"
          indeterminate
        />
      </div>
    </div>
    <ButtonEl
      :disabled="isSaveButtonDisabled"
      :is-loading="isCreating"
      icon="add"
      text="Speichern"
      class="pl-4"
      @click="save"
    />
  </div>
</template>

<script setup lang="ts">
import GeoChart from '@/components/charts/GeoChart.vue';
import { useRouter } from 'vue-router';
import { useSyncGeoMapPostalCodeSelection } from '@/composables/syncGeoMapPostalCodeSelection';
import TopHeaderBar from '@/components/header-component/TopHeaderBar.vue';
import DropDownMultipleGrouped from '@/components/dropDownMultipleGrouped/DropDownMultipleGrouped.vue';
import InputEl from '@/components/input/InputEl.vue';
import FormValidator from '@/components/FormValidator.vue';
import { ref, watch, computed } from 'vue';
import {
  convertMetaDataToFormRules,
  restGetOrFetchMetaData,
  restPost,
} from '@/utils/rest-utils';
import { SalesRegionDto } from '@/apps/usecase-4/sales-regions/sales-region-dto';
import ButtonEl from '@/components/button/ButtonEl.vue';
import CheckboxEl from '@/components/CheckboxEl/CheckboxEl.vue';
import RadioButtonGroup from '@/components/RadioButtonGroup/RadioButtonGroup.vue';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import axios from '@/utils/axiosHelper';

const router = useRouter();

const response = await axios({
  method: 'GET',
  url: `/api/usecase-4/assigned-postal-codes`,
});

const maxSelectablePostalCodesResponse = await axios({
  method: 'GET',
  url: `/api/usecase-4/max-selectable-postal-codes`,
});

const assignedPostalCodes = computed(() => {
  if (response.status === 200) {
    return response.data;
  }
  return [];
});

const maxSelectablePostalCodes = computed(() => {
  if (maxSelectablePostalCodesResponse.status === 200) {
    return maxSelectablePostalCodesResponse.data.max_selectable_postal_codes;
  }
  return 150;
});

const displayExistingProjects = ref(false);
const groupBy = ref<'county_name' | 'admin_district_name'>('county_name');

const {
  setSelected,
  setUnSelected,
  selected,
  postalCodeSelectItems,
  postalCodes,
} = useSyncGeoMapPostalCodeSelection(groupBy);

const preprocessedPostalCodes = computed(() => {
  if (!displayExistingProjects.value) {
    return postalCodes.value;
  }
  const postalCodesInExistingSalesRegions =
    assignedPostalCodes.value.postal_codes;
  const style = getComputedStyle(document.documentElement);
  const color = `rgb(${style.getPropertyValue('--core-color1')})`;
  return postalCodes.value.map((item) => {
    const isInExistingSalesRegions = postalCodesInExistingSalesRegions.includes(
      parseInt(item.name),
    );
    return {
      ...item,
      areaColor: isInExistingSalesRegions ? color : undefined,
    };
  });
});

const isSaveButtonDisabled = computed(() => {
  const validAmountOfPostalCodesSelected =
    selected.value.length >= 1 &&
    selected.value.length <= maxSelectablePostalCodes.value;
  return (
    !isFormValid.value || !validAmountOfPostalCodesSelected || isCreating.value
  );
});

const salesRegion = ref(new SalesRegionDto());
const isFormValid = ref(false);

const salesRegionMetaData = await restGetOrFetchMetaData(SalesRegionDto);
const salesRegionFormRules = convertMetaDataToFormRules(salesRegionMetaData);

const isCreating = ref(false);

async function save() {
  isCreating.value = true;
  try {
    const res = await restPost(salesRegion.value, [
      'title',
      'postal_codes',
      'status',
    ]);
    setTimeout(() => {
      router.push({ name: 'SalesRegionsList' });
    }, 1000);
  } catch (e) {
    console.error(e);
    isCreating.value = false;
  }
}

const customPreviewText = computed(() => {
  if (selected.value.length === 0) {
    return undefined;
  }
  return selected.value.length + ' PLZs ausgewählt';
});

watch(selected, (newValue) => {
  salesRegion.value.postal_codes = newValue;
});
</script>

<style scoped lang="scss">
.sales-region-create-container {
  min-height: 600px;
}

.geo-chart-custom-height {
  min-height: 500px;
}
</style>
