<template>
  <div class="flex justify-between">
    <SlideContainer
      ref="filterContainer"
      position="left"
      title="Filter"
      :prevent-close-from-outside="modalIsOpen"
      @update:slide-container-is-open="slideContainerIsOpen = $event"
    >
      <template #activator="{ openModal }">
        <ButtonEl
          :text="
            someFilterApplied && selectedFilterSetName
              ? `Filtereinstellung - ${selectedFilterSetName}`
              : 'Filter setzen'
          "
          icon="tune"
          @click="openModal"
        />
      </template>
      <EvaluationFilterWrapper
        :slide-container-is-open
        :sales-region-id="salesRegionId"
        @apply:filter="fetchResultData($event)"
        @update:modal-is-open="modalIsOpen = $event"
        @changed:filter-is-dirty="filterChanged = $event"
        @changed:filter-id="leadList.filterset = $event"
        @update:filter-sets="filterSets = $event"
      />
    </SlideContainer>
    <div
      class="flex align-center text-title-neutral p-1 rounded-md standard-elevation-0-dark"
    >
      <IconWrapper icon="source" class="ml-2.5 mr-2" />
      <p class="caption-1 mt-1">
        Die Datenbasis für die hier angezeigten Informationen wird regelmäßig
        aktualisert
      </p>
      <p
        class="flex align-center pt-1 subtitle-4 text-neutral h-10 px-2.5 bg-subtle rounded-md ml-2.5"
      >
        Letzte Aktualisierung: 01.01.2025
      </p>
    </div>
    <ButtonEl
      :disabled="
        !userStore.uc4CanAddLeadList ||
        filterChanged ||
        leadList.filterset === null
      "
      text="Leadliste erstellen"
      :color="
        FilterIdsWithLeadList.has(leadList.filterset) ? 'warning' : 'success'
      "
      :icon="
        FilterIdsWithLeadList.has(leadList.filterset) ? 'info' : 'playlist_play'
      "
      :icon-right="true"
      @click="toggleLeadlistCreate"
    />
    <VuetifyDialog
      v-if="showLeadlistCreate"
      v-model="showLeadlistCreate"
      title="Leadliste erstellen"
    >
      <template #content>
        <FormValidator v-model="leadlistInputsValid">
          <InputEl
            v-model="leadList.title"
            label="Name"
            :rules="{ required: true }"
            :unique-check-url="`/api/usecase-4/leadlist/names/?sales_region_id=${props.salesRegionId}`"
          />
          <InputEl v-model="selectedFilterSetName" disabled label="Filterset" />
          <InputEl v-model="leadList.description" label="Notiz" />
        </FormValidator>
      </template>
      <template #actions>
        <div class="flex justify-between w-full">
          <ButtonEl
            text="Abbrechen"
            color="color2"
            @click="showLeadlistCreate = false"
          />
          <ButtonEl
            text="Erstellen"
            :disabled="!leadlistInputsValid"
            @click="createLeadList()"
          />
        </div>
      </template>
    </VuetifyDialog>
  </div>

  <ExpansionPanel
    v-if="dataAvailable"
    :panels="[
      'Vertriebskennzahlen',
      'Strukturübersicht',
      'Wärmepumpenpotenzial',
      'Wärmepumpenpotenzial: TOP 10 Postleitzahlen',
    ]"
    eager
    all-panels-open
    multiple
    :show-circle-number="false"
    class="space-y-5 gap-0"
  >
    <template #panel1>
      <div class="grid grid-cols-3 gap-5">
        <div
          v-for="column in [cardsColumn1, cardsColumn2, cardsColumn3]"
          :key="column"
        >
          <div
            v-for="field in column"
            :key="field"
            class="space-y-[5px] w-full"
          >
            <DetailBox
              v-for="item in field"
              :key="item"
              v-bind="item"
              tool-tip-at-end
              :minimum-digits="item.decimals"
              :maximum-digits="item.decimals"
              :tool-tip-for-label="item.infoTag"
            >
              <template #tooltip>
                <PercentageCircle :percentage="item.percentage" />
              </template>
            </DetailBox>
          </div>
        </div>
      </div>
    </template>
    <template #panel2>
      <div class="grid grid-cols-4 h-[420px] gap-2.5">
        <BasicPieChart
          class="h-full"
          :chart-data="pieTypeData"
          title="Gebäudetyp"
          :custom-radius="['35%', '55%']"
          :series-position="['50%', '36%']"
          legend-orient="horizontal"
          chart-unit="Gebäude"
          break-unit
          legend-top="255px"
          :legend-right="null"
          :show-label="false"
          custom-title-tag="h5"
          custom-title-classes="min-h-9"
          custom-wrapper-padding="pb-5"
          :legend-line-break="false"
        />
        <BasicPieChart
          class="h-full"
          :chart-data="pieYearData"
          :title="'Gebäude nach\n Baualtersklassen'"
          :custom-radius="['35%', '55%']"
          :series-position="['50%', '36%']"
          legend-orient="horizontal"
          chart-unit="Gebäude"
          break-unit
          legend-top="255px"
          :legend-right="null"
          :show-label="false"
          custom-title-tag="h5"
          custom-title-classes="min-h-9"
          custom-wrapper-padding="pb-5"
          :legend-line-break="false"
        />
        <BasicPieChart
          class="w-[300px] h-full"
          :chart-data="pieSectorData"
          :title="'Gebäude nach\n Sektor'"
          :custom-radius="['35%', '55%']"
          :series-position="['50%', '36%']"
          legend-orient="horizontal"
          chart-unit="Gebäude"
          break-unit
          legend-top="255px"
          :legend-right="null"
          :show-label="false"
          custom-title-tag="h5"
          custom-title-classes="min-h-9"
          custom-wrapper-padding="pb-5"
          :legend-line-break="false"
        />
        <BasicPieChart
          class="w-[300px] h-full"
          :chart-data="pieSourceData"
          :title="'Gebäude nach\n Energieträger heute'"
          :custom-radius="['35%', '55%']"
          :series-position="['50%', '36%']"
          legend-orient="horizontal"
          chart-unit="Gebäude"
          break-unit
          legend-top="255px"
          :legend-right="null"
          :show-label="false"
          custom-title-tag="h5"
          custom-title-classes="min-h-9"
          custom-wrapper-padding="pb-5"
          :legend-line-break="false"
        />
      </div>
      <div class="w-full h-32 pr-5">
        <BarChartVertical
          :series="verticalStackedBarChartData"
          :show-x-axis-line="false"
          :show-x-axis-label="false"
          :show-y-axis="false"
          :show-tooltip-axis-pointer="false"
          :labels="['']"
          title="Gebäude nach Bestandskunden"
        />
      </div>
    </template>
    <template #panel3>
      <div class="flex justify-between w-full mb-9">
        <DropDown
          v-model="selectedColoring"
          label="Einfärbung der Balken nach"
          placeholder="Keine Einfärbung"
          class="w-[320px]"
          :items-data="colorings"
          items-data-key="label"
        />
        <DropDown
          v-model="selectedUnit"
          label="Einheit"
          placeholder="Keine Einfärbung"
          class="w-[250px]"
          :items-data="units"
          items-data-key="label"
        />
      </div>
      <div>
        <BarChartBasic
          :stacks="heatDensityData"
          title="Wärmeliniendichte"
          :x-axis-data="heatDensityThresholds"
          x-axis-name="kWh/m"
          :y-axis-name="unitSetting.unit"
          :chart-unit="unitSetting.unit"
          grid-left="4%"
          grid-right="5%"
          class="min-h-[500px] mb-7"
          chart-classes="h-full w-full"
          :show-legend="false"
          bar-width="32"
          tool-tip-show-totals
        />
        <div class="relative">
          <BarChartBasic
            :stacks="heatPumpData"
            title="Wärmepumpen-Ampel"
            :x-axis-data="heatPumpThresholds"
            :y-axis-name="unitSetting.unit"
            :chart-unit="unitSetting.unit"
            grid-left="4%"
            class="min-h-[500px] mb-7"
            chart-classes="h-full w-full"
            :show-legend="false"
            bar-width="32"
            tool-tip-show-totals
          />
          <HeatPumpLightTag potential="Grün" style="right: 78.2%" />
          <HeatPumpLightTag potential="Gelb" style="right: 55.9%" />
          <HeatPumpLightTag potential="Rot" style="right: 33.6%" />
          <HeatPumpLightTag potential="Unbekannt" style="right: 11.1%" />

          <ToolTip
            class="absolute z-10"
            style="top: 2.8%; right: 40.5%"
            :slot-max-width="500"
          >
            <template #text>
              Die Wärmepumpen-Ampel bewertet, welcher Anteil des Wärmebedarfs
              eines Gebäudes durch eine Luft-Wasser-Wärmepumpe gedeckt werden
              kann. Dabei werden zentrale Planungsfaktoren wie die durch den
              Betrieb verursachten Schallemissionen berücksichtigt.
              <br /><br />Bemessen am Wärmebedarfsdeckungsgrad erfolgt eine
              Bewertung in Form einer Ampel in drei Farben. <br />
              <br />
              <ul>
                <li>
                  Rot: Wärmepumpe unwahrscheinlich (Wärmebedarfsdeckungsgrad
                  durch WP zwischen 0 und kleiner 70%)
                </li>
                <li>
                  Gelb: Wärmepumpe vermutlich mit anderen Maßnahmen realisierbar
                  (Wärmebedarfsdeckungsgrad durch WP zwischen 70 und kleiner
                  100%)
                </li>
                <li>
                  Grün: Wärmepumpe wahrscheinlich möglich
                  (Wärmebedarfsdeckungsgrad durch WP bei möglichst hoher
                  Wärmepumpenleistung 100%)
                </li>
              </ul>
            </template>
          </ToolTip>
        </div>
        <BarChartBasic
          :stacks="ownershipData"
          title="Eigentümerquote"
          :x-axis-data="ownershipThresholds"
          :y-axis-name="unitSetting.unit"
          :chart-unit="unitSetting.unit"
          grid-left="4%"
          class="min-h-[500px]"
          chart-classes="h-full w-full"
          :show-legend="false"
          bar-width="32"
          tool-tip-show-totals
        />
        <BarChartBasic
          :stacks="demographyData"
          title="Demografie nach Altersgruppen"
          :x-axis-data="demographyThresholds"
          :y-axis-name="unitSetting.unit"
          :chart-unit="unitSetting.unit"
          grid-left="4%"
          class="min-h-[500px]"
          chart-classes="h-full w-full"
          :show-legend="true"
          bar-width="32"
          tool-tip-show-totals
        />
      </div>
    </template>
    <template #panel4>
      <div class="flex justify-between gap-5 z-50">
        <DropDown
          v-model="selectedColoringTop10"
          label="Einfärbung der Balken nach"
          placeholder="Keine Einfärbung"
          class="w-[320px]"
          :items-data="colorings"
          items-data-key="label"
        />
        <DropDown
          v-model="selectedUnitPostalCode"
          label="Einheit"
          :initial-selection="true"
          class="w-[250px]"
          :items-data="units"
          items-data-key="label"
        />
      </div>
      <div class="w-full h-[400px] pr-5 relative">
        <BarChartVertical
          :series="top10PostalCodeData"
          :show-y-axis-line="false"
          :show-tooltip-axis-pointer="false"
          :labels="top10postalCodesNames"
          :legend="{ show: top10PostalCodeData.length > 1 }"
          grid-bottom="10%"
          tool-tip-show-totals
        />
      </div>
    </template>
  </ExpansionPanel>
  <div v-else class="flex-grow flex justify-center items-center">
    Der aktuell gewählte Filter hat keine Ergebnisse geliefert. Bitte versuchen
    Sie es mit einer anderen Kombination.
  </div>
</template>

<script setup>
import { toLocaleStr } from '@/utils/formatUtils';
import axios from '@/utils/axiosHelper';
import DetailBox from '@/apps/features/boxes/DetailBox.vue';
import IconHeatWavesCircleOutlined from '@/assets/icons/custom/energy-grid/IconHeatWavesCircleOutlined.vue';
import IconHeatWavesCircleFilled from '@/assets/icons/custom/energy-grid/IconHeatWavesCircleFilled.vue';
import BarChartVertical from '@/components/charts/BarChartVertical.vue';
import BarChartBasic from '@/components/charts/BarChartBasic.vue';
import BasicPieChart from '@/components/charts/basic-pie-chart/BasicPieChart.vue';
import { computed, ref, watch } from 'vue';
import {
  AZUR_BLUE_70,
  BUILDINGTYPE_BY_ENUM,
  CONSTRUCTION_YEAR_UC4_BY_ENUM,
  CUSTOMER_AT_BY_ENUM,
  HEATING_TYPE_BY_ENUM,
  SECTOR_BY_ENUM,
} from '@/configs/color-constants';
import ButtonEl from '@/components/button/ButtonEl.vue';
import IconWrapper from '@/components/IconWrapper/IconWrapper.vue';
import PercentageCircle from '@/apps/usecase-4/evaluation/components/PercentageCircle.vue';
import DropDown from '@/components/DropDown/DropDown.vue';
import SlideContainer from '@/components/SlideContainer.vue';
import EvaluationFilterWrapper from './components/EvaluationFilterWrapper.vue';
import VuetifyDialog from '@/components/vuetifyDialog/VuetifyDialog.vue';
import InputEl from '@/components/input/InputEl.vue';
import FormValidator from '@/components/FormValidator.vue';
import { useUsers } from '@/apps/auth/users-store';
import { useToastStore } from '@/stores/toast-store';
import { LeadListDto } from '@/apps/usecase-4/evaluation/components/lead-list-dto';
import { restPost } from '@/utils/rest-utils';
import ToolTip from '@/components/toolTip/ToolTip.vue';
import HeatPumpLightTag from './components/HeatPumpLightTag.vue';
import { SECTOR_LEGENDS_ORDERED } from '@/utils/chart-utils';
import ExpansionPanel from '@/components/ExpansionPanel/ExpansionPanel.vue';

const { showToast } = useToastStore();
const userStore = useUsers();
const props = defineProps({
  salesRegionId: {
    type: String,
    required: true,
  },
  salesRegion: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['created:leadList']);

// ----------- Fetch data
const filterContainer = ref(null);
const someFilterApplied = ref(false);

async function fetchResultData(filter) {
  if (Object.keys(filter).length > 0) {
    showToast({
      color: 'info',
      message: 'Lade Ergebnisse...',
      timeout: 10000000000000,
    });
  }

  try {
    const dataResp = await axios({
      method: 'GET',
      url: `/api/usecase-4/results/${props.salesRegionId}`,
      params: filter,
    });
    resultData.value = dataResp.data;
    fullRegion.value = dataResp.data.full_sales_region_aggregation;

    someFilterApplied.value = Object.keys(filter).length > 0;

    if (someFilterApplied.value) {
      filterContainer.value.closeModal();
      showToast({
        color: 'success',
        message: 'Ergebnisse erfolgreich geladen',
        timeout: 3000,
      });
    }
  } catch (error) {
    showToast({
      color: 'error',
      message: 'Fehler beim Laden der Ergebnisse',
      timeout: 3000,
    });
  }
}

const resultData = ref();
const fullRegion = ref();

await fetchResultData({});

const dataAvailable = computed(() => resultData.value && fullRegion.value);

// -------------- setting items
const units = ref([
  {
    label: 'Anzahl Gebäude',
    value: 'buildings',
  },
  {
    label: 'Wärmelast in MW(th)',
    value: 'heatLoad',
  },
  {
    label: 'Wärmebedarf in MWh(th)/a',
    value: 'heatDemand',
  },
]);

const colorings = ref([
  {
    label: 'Keine Einfärbung',
    value: 'none',
  },
  {
    label: 'Baualtersklassen',
    value: 'buildingClass',
  },
  {
    label: 'Sektor',
    value: 'sector',
  },
  {
    label: 'Gebäudetyp',
    value: 'buildingType',
  },
  {
    label: 'Energieträger heute',
    value: 'energySource',
  },
  {
    label: 'Bestandskunden',
    value: 'existingCustomers',
  },
]);

// ---------------- Info Boxes in Tab Vertriebskennzahlen -----------------
const cardsColumn1 = computed(() => {
  if (!dataAvailable.value) {
    return [];
  }
  return [
    [
      {
        percentage:
          (resultData.value.count_customers_enbw /
            fullRegion.value.count_customers_enbw) *
          100,
        icon: 'handshake',
        label: 'Bestandskund:innen EnBW',
        value: resultData.value.count_customers_enbw,
        infoTag: 'Bezieht sich jeweils auf ein Gebäude',
        decimals: 0,
      },
      {
        percentage:
          (resultData.value.count_buildings /
            fullRegion.value.count_buildings) *
          100,
        icon: 'maps_home_work',
        label: 'Anzahl Gebäude',
        value: resultData.value.count_buildings,
        decimals: 0,
      },
      {
        percentage:
          (resultData.value.count_residents /
            fullRegion.value.count_residents) *
          100,
        icon: 'groups',
        label: 'Einwohner:innen',
        value: resultData.value.count_residents,
        decimals: 0,
      },
    ],
  ];
});

const cardsColumn2 = computed(() => {
  if (!dataAvailable.value) {
    return [];
  }
  return [
    [
      {
        percentage:
          (resultData.value.demand_kwh / fullRegion.value.demand_kwh) * 100,
        icon: IconHeatWavesCircleOutlined,
        label: 'Wärmebedarf',
        value: resultData.value.demand_kwh,
        decimals: 0,
        unit: 'MWh(th)/a',
      },
      {
        percentage:
          (resultData.value.demand_kw / fullRegion.value.demand_kw) * 100,
        icon: IconHeatWavesCircleFilled,
        label: 'Wärmelast',
        value: resultData.value.demand_kw,
        unit: 'MW(th)',
        decimals: 0,
      },
      {
        percentage:
          (resultData.value.commodity_potential_eur_a /
            fullRegion.value.commodity_potential_eur_a) *
          100,
        icon: 'inventory_2',
        label: 'Commodity-Potenzial',
        value: resultData.value.commodity_potential_eur_a,
        decimals: 1,
        unit: 'Mio. €/a',
      },
    ],
  ];
});

const cardsColumn3 = computed(() => {
  if (!dataAvailable.value) {
    return [];
  }
  return [
    [
      {
        percentage:
          (resultData.value.count_postal_codes /
            fullRegion.value.count_postal_codes) *
          100,
        icon: 'mark_email_read',
        label: 'Anzahl Postleitzahlen',
        value: resultData.value.count_postal_codes,
        decimals: 0,
      },
      {
        percentage:
          (resultData.value.count_municipalities /
            fullRegion.value.count_municipalities) *
          100,
        icon: 'domain',
        label: 'Anzahl Gemeinden',
        value: resultData.value.count_municipalities,
        decimals: 0,
      },
      {
        percentage:
          (resultData.value.count_counties / fullRegion.value.count_counties) *
          100,
        icon: 'shield',
        label: 'Anzahl Landkreise',
        value: resultData.value.count_counties,
        decimals: 0,
      },
    ],
  ];
});

// ----- Tab Strukturübersicht ------
const getPieData = (data, dataKey, style) => {
  return data.map((e) => {
    const choice = style[e[dataKey]];
    return {
      value: e.count_buildings,
      color: choice.color,
      name: choice.label,
    };
  });
};

const pieYearData = computed(() => {
  if (!dataAvailable.value) {
    return [];
  }
  return getPieData(
    resultData.value.by_construction_year_class,
    'construction_year_class_uc4',
    CONSTRUCTION_YEAR_UC4_BY_ENUM,
  );
});

const pieTypeData = computed(() => {
  if (!dataAvailable.value) {
    return [];
  }
  return getPieData(
    resultData.value.by_building_type,
    'building_type',
    BUILDINGTYPE_BY_ENUM,
  );
});

const pieSectorData = computed(() => {
  if (!dataAvailable.value) {
    return [];
  }
  return getPieData(resultData.value.by_sectors, 'sector', SECTOR_BY_ENUM);
});

const pieSourceData = computed(() => {
  if (!dataAvailable.value) {
    return [];
  }
  return getPieData(
    resultData.value.by_heating_type,
    'heating_type',
    HEATING_TYPE_BY_ENUM,
  );
});

const verticalStackedBarChartData = computed(() => [
  {
    name: 'EnBW',
    type: 'bar',
    stack: 'Total',
    barWidth: '20px',
    emphasis: {
      focus: 'none',
    },
    data: [resultData.value.count_customers_enbw],
    itemStyle: {
      color: CUSTOMER_AT_BY_ENUM.EnBW.color,
    },
  },
  {
    name: 'Sonstige',
    type: 'bar',
    stack: 'Total',
    barWidth: '20px',
    emphasis: {
      focus: 'none',
    },
    data: [
      resultData.value.count_buildings - resultData.value.count_customers_enbw,
    ],
    itemStyle: {
      color: CUSTOMER_AT_BY_ENUM.other.color,
    },
  },
]);

// ---------------- Wärmepumpenpotenzial Top 10 Kommunen -----------------
const selectedColoringTop10 = ref(null);
const selectedUnitPostalCode = ref('buildings');

const unitSettingPostalCode = computed(() => {
  if (selectedUnitPostalCode.value === 'buildings')
    return { dataKey: 'count_buildings', unit: 'Gebäude' };
  if (selectedUnitPostalCode.value === 'heatLoad')
    return { dataKey: 'demand_kw', unit: 'MW(th)' };
  if (selectedUnitPostalCode.value === 'heatDemand')
    return { dataKey: 'demand_kwh', unit: 'MWh(th)/a' };
  throw Error('unit in dropdown must be set');
});

const top10PostalCodes = computed(() => {
  if (!dataAvailable.value) {
    return [];
  }
  return [...resultData.value.histogram_postal_codes]
    .sort((a, b) => {
      const key = unitSettingPostalCode.value.dataKey;
      return a[key] - b[key];
    })
    .slice(-10);
});

const top10postalCodesNames = computed(() => {
  return top10PostalCodes.value.map((e) => e.postal_code);
});

const top10PostalCodeData = computed(() => {
  return getHistogramData(
    top10PostalCodes.value,
    unitSettingPostalCode.value.dataKey,
    selectedColoringTop10.value,
    'Postleitzahl',
  );
});
// ----------------- HISTOGRAM

const selectedColoring = ref('none');
const selectedUnit = ref('buildings');

function getHistogramData(data, unit, selectedGroupKey, simpleSeriesName) {
  if (!dataAvailable.value) {
    return [];
  }
  const mappings = {
    buildingClass: {
      accessor: (item) => item.by_construction_year_class,
      enumMap: CONSTRUCTION_YEAR_UC4_BY_ENUM,
      key: 'construction_year_class_uc4',
    },
    sector: {
      accessor: (item) => item.by_sectors,
      enumMap: SECTOR_BY_ENUM,
      key: 'sector',
    },
    buildingType: {
      accessor: (item) => item.by_building_type,
      enumMap: BUILDINGTYPE_BY_ENUM,
      key: 'building_type',
    },
    energySource: {
      accessor: (item) => item.by_heating_type,
      enumMap: HEATING_TYPE_BY_ENUM,
      key: 'heating_type',
    },
    existingCustomers: {
      accessor: (item) => item.by_customers,
      enumMap: CUSTOMER_AT_BY_ENUM,
      key: 'existing_customer_at',
    },
  };
  const selectedMapping = mappings[selectedGroupKey];
  if (selectedMapping) {
    const { accessor, enumMap, key } = selectedMapping;
    const classes = accessor(data[0]).map((e) => e[key]);
    const classData = classes.map((e, index) => ({
      name: enumMap[e].label,
      stack: 'total',
      itemStyle: { color: enumMap[e].color },
      data: data.map((c) => accessor(c)[index][unit]),
      type: 'bar',
    }));
    if (selectedGroupKey === 'sector') {
      return classData.sort(
        (a, b) =>
          SECTOR_LEGENDS_ORDERED.indexOf(a.name) -
          SECTOR_LEGENDS_ORDERED.indexOf(b.name),
      );
    }
    return classData;
  }
  return [
    {
      name: simpleSeriesName,
      itemStyle: { color: AZUR_BLUE_70 },
      data: data.map((e) => e[unit]),
      type: 'bar',
    },
  ];
}

const unitSetting = computed(() => {
  if (selectedUnit.value === 'buildings')
    return { dataKey: 'count_buildings', unit: 'Gebäude' };
  if (selectedUnit.value === 'heatLoad')
    return { dataKey: 'demand_kw', unit: 'MW(th)' };
  if (selectedUnit.value === 'heatDemand')
    return { dataKey: 'demand_kwh', unit: 'MWh(th)/a' };
  throw Error('unit in dropdown must be set');
});

const setAxisBins = (
  dataSet,
  dataKey,
  unit = '',
  unknown = 'unknown',
  multiplier = 1,
) => {
  if (!dataAvailable.value) {
    return [];
  }
  const data = resultData.value[dataSet];

  return data.map((entry, index) => {
    const currentValue = data[index][dataKey];
    if (currentValue === unknown) {
      return 'Unbekannt';
    }
    const start = index > 0 ? data[index][dataKey] * multiplier + 1 : 0;
    const nextValue = index + 1 < data.length ? data[index + 1][dataKey] : null;
    const end = nextValue !== unknown ? nextValue * multiplier : null;
    return end
      ? `${toLocaleStr(start)}${unit} bis ${toLocaleStr(end)}${unit}`
      : `über ${toLocaleStr(start - 1)}${unit}`;
  });
};
const heatDensityThresholds = computed(() =>
  setAxisBins('histogram_heat_density', 'heat_density'),
);
const heatDensityData = computed(() => {
  const unit = unitSetting.value.dataKey;
  return getHistogramData(
    resultData.value.histogram_heat_density,
    unit,
    selectedColoring.value,
    'Wärmeliniendichte',
  );
});
const ampelTranslate = {
  green: 'Grün',
  yellow: 'Gelb',
  red: 'Rot',
  unk: 'Unbekannt',
};
const heatPumpThresholds = computed(() => {
  if (!dataAvailable.value) {
    return [];
  }
  return resultData.value.histogram_ampel.map((e) => ampelTranslate[e.ampel]);
});
const heatPumpData = computed(() => {
  const unit = unitSetting.value.dataKey;
  return getHistogramData(
    resultData.value.histogram_ampel,
    unit,
    selectedColoring.value,
    'Ampel',
  );
});

const ownershipThresholds = computed(() =>
  setAxisBins(
    'histogram_ownership_rate',
    'ownership_rate',
    '%',
    'unknown',
    100,
  ),
);
const ownershipData = computed(() => {
  const unit = unitSetting.value.dataKey;
  return getHistogramData(
    resultData.value.histogram_ownership_rate,
    unit,
    selectedColoring.value,
    'Eigentümerquote Wohngebäude',
  );
});
const demographyThresholds = computed(() =>
  setAxisBins('histogram_age', 'age'),
);
const demographyData = computed(() => {
  const unit = unitSetting.value.dataKey;
  return getHistogramData(
    resultData.value.histogram_age,
    unit,
    selectedColoring.value,
    'Demografie',
  );
});
// ------- filter
const modalIsOpen = ref(false);
const slideContainerIsOpen = ref(false);
const filterChanged = ref(false);

watch(filterChanged, (newVal) => {
  if (newVal === true && leadList.value.filterset) {
    showToast({
      color: 'warning',
      timeout: -1,
      message:
        'Sie haben die Filtereinstellung geändert. Um eine Leadliste' +
        ' erstellen zu können, müssen Sie die Filtereinstellung ' +
        'speichern, oder den Filter auf seinen gespeicherten Stand zurücksetzen',
    });
  }
});
// ------- lead list
// TODO: Disable save if leadlist for this filer and region exists
const showLeadlistCreate = ref(false);
const leadlistInputsValid = ref(false);
const leadList = ref(new LeadListDto());
leadList.value.sales_region = props.salesRegionId;
async function createLeadList() {
  const leadListCreated = await restPost(leadList.value, [
    'title',
    'description',
    'sales_region',
    'filterset',
  ]);
  console.log(leadListCreated);
  showLeadlistCreate.value = false;
  emit('created:leadList');
  showToast({
    color: 'success',
    message: 'Leadliste wurde erstellt.',
  });
}
const FilterIdsWithLeadList = computed(() => {
  const filterIds = new Set(
    props.salesRegion.lead_lists.map(({ filterset }) => filterset),
  );

  return filterIds;
});

const filterSets = ref(null);

const selectedFilterSetName = computed(() => {
  return filterSets.value?.find((set) => set.id === leadList.value.filterset)
    ?.title;
});

function toggleLeadlistCreate() {
  if (FilterIdsWithLeadList.value.has(leadList.value.filterset)) {
    showToast({
      color: 'warning',
      timeout: 5000,
      message:
        'Für dieses Filterset existiert bereits eine Leadliste, bitte wählen Sie ein anderes Filterset oder speichern Sie den Filter, um eine Leadliste zu erstellen.',
    });

    return;
  }
  showLeadlistCreate.value = true;
}
</script>
